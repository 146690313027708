import { AModel } from '@/types/class_helper';

export class Basedata extends AModel {
	bu: string;
	bu_info: {
		id: string;
		name: string;
		address: {
			country: string;
			postcode: string;
			city: string;
			street: string;
			houseno: string;
		};
		vat_number: string;
		tax_id: null | string;
		ceo: null | string;
		contact: {
			email: string;
			phone: {
				free: string;
				paid: null | string;
				active_booking: null | string;
				pro_abo: null | string;
			};
			whatsapp: null | string;
		};
		files: {
			damage_report: null | string;
			accident_report: null | string;
			booking_leaflet: null | string;
			brochure_fleet_partner: null | string;
			brochure_location_partner: null | string;
		};
	};
	min_app_version: string;
	app_store_rating: boolean;
	promo_text: null | string;
	countries: Array<{
		code: string;
		zone: null | number;
		pcp: string;
	}>;
	max_deductibles: Array<{
		type: string;
		value: number;
		currency: string;
	}>;
	prices: Array<{
		type: string;
		price: number;
		currency: string;
	}>;
	agb_link: null | string;
	agb_abo_link: null | string;
	agb_b2b_link: null | string;
	data_protection_link: null | string;
	data_protection_phone: null | string;
	abo_withdrawal: null | string;
	reviews: {
		count: number;
		value: number;
	};
	cities: Array<string>;
	working_hours: {
		from: string;
		to: string;
	};
	live_chat_url: null | string;
	deposit_refund_after_days: number;
	features: {
		'6_hour_blocks': boolean;
		peaktime_slots: Array<number>;
		peaktime2_slots: Array<number>;
		offtime_slots: Array<number>;
		protection_packages: Array<string>
	};
	size_groups: {
		l: {
			threshold: number;
			banner: null | string;
		};
		xl: {
			threshold: number;
			banner: null | string;
		};
		xxl: {
			threshold: number;
			banner: null | string;
		};
	};
	damage_report_form_url: null | string;

	constructor(opt?: Partial<Basedata>) {
		super();

		// Default values (all props)
		this.bu = '';
		this.bu_info = {
			id: '',
			name: '',
			address: {
				country: '',
				postcode: '',
				city: '',
				street: '',
				houseno: ''
			},
			vat_number: '',
			tax_id: null,
			ceo: null,
			contact: {
				email: '',
				phone: {
					free: '',
					paid: null,
					active_booking: null,
					pro_abo: null
				},
				whatsapp: null
			},
			files: {
				damage_report: null,
				accident_report: null,
				booking_leaflet: null,
				brochure_fleet_partner: null,
				brochure_location_partner: null
			}
		};
		this.min_app_version = '1.0.0';
		this.app_store_rating = true;
		this.promo_text = null;
		this.countries = [];
		this.max_deductibles = [];
		this.prices = [];
		this.agb_link = null;
		this.agb_abo_link = null;
		this.agb_b2b_link = null;
		this.data_protection_link = null;
		this.data_protection_phone = null;
		this.abo_withdrawal = null;
		this.reviews = {
			count: 1,
			value: 5
		};
		this.cities = [];
		this.working_hours = {
			from: '06:00',
			to: '22:00'
		};
		this.live_chat_url = null;
		this.deposit_refund_after_days = 21;
		this.features = {
			'6_hour_blocks': false,
			peaktime_slots: [],
			peaktime2_slots: [],
			offtime_slots: [],
			protection_packages: ['BASIC','STANDARD','ZERO']
		};
		this.size_groups = {
			l: {
				threshold: 7,
				banner: null
			},
			xl: {
				threshold: 11,
				banner: null
			},
			xxl: {
				threshold: 11,
				banner: null
			}
		};
		this.damage_report_form_url = null;
		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Basedata, Partial<Basedata>>(this, sanitized);
		}
	}
}

export default Basedata;
